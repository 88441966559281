import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Alert } from 'react-bootstrap';
import { FaSync } from 'react-icons/fa';
import { format } from 'date-fns';
import {
  CalculoCondicao,
  InputMoney,
  InputDate,
  InputSelect,
  CalculoResponse,
} from '~/components/NovosInputs';
import { ClearButton, ConfirmButton } from '~/components/Buttons';
import TableFinanceiro from './TableFinanceiro';
import { useNFOutrasOperacao } from '../../NFOutrasOperacoesContext';
import Separator from '~/components/Separator';
// eslint-disable-next-line import/named
import { ContainerScreens, ButtonItens, ContainerTotal } from './styles';
import getFinalizadoras from '../../services/getFinalizadoras';
import getCondicoes from '../../services/getCondicoes';
import { formatCurrencyAsText } from '~/utils/functions';

export const Finaceiro: React.FC = () => {
  const {
    dtaEmissao,
    dtaEntrada,
    formStateFinanceiro,
    controlFinanceiro,
    registerFinanceiro,
    setValueFinanceiro,
    getValueFinanceiro,
    watchFinanceiro,
    setCurrentTab,
    setFinalizadoras,
    finalizadoras,
    setCondicoes,
    addItemFinaceiro,
    onClearFinaceiro,
    updateFinaceiro,
    financeiroCalcular,
    itensFinaceiro,
    disable,
    total,
  } = useNFOutrasOperacao();
  const [datasNaoInformadas, setDatasNaoInformadas] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      const optionsFinalizadora = await getFinalizadoras();
      setFinalizadoras(optionsFinalizadora);

      const optionsCondicao = await getCondicoes();
      setCondicoes(optionsCondicao);
    })();
  }, []);

  useEffect(() => {
    if (itensFinaceiro.length !== 0) {
      const totalParcelas = itensFinaceiro.reduce(
        (acc, parcela) => acc + parcela.val_parcela,
        0,
      );
      const resultado = total.Total_Nota_Fiscal - totalParcelas;
      setTimeout(() => {
        setValueFinanceiro(
          'val_financeiro',
          formatCurrencyAsText(resultado > 0 ? resultado : 0),
        );
      }, 20);
    } else {
      setValueFinanceiro(
        'val_financeiro',
        formatCurrencyAsText(total.Total_Nota_Fiscal),
      );
    }
  }, [itensFinaceiro, total.Total_Nota_Fiscal]);

  const handleVencimento = useCallback(
    (condicaoData: CalculoResponse) => {
      if (condicaoData.error === 'Datas Devem ser informadas.') {
        setDatasNaoInformadas(true);
        return;
      }
      if (condicaoData.error !== 'Datas Devem ser informadas.') {
        setDatasNaoInformadas(false);
      }
      setValueFinanceiro(
        'dta_vencimento',
        format(new Date(condicaoData.vencimento), 'yyyy-MM-dd'),
      );
      setValueFinanceiro('num_condicao', condicaoData.numDias);
      setValueFinanceiro('condicao', condicaoData.condicao);
    },
    [setValueFinanceiro],
  );
  return (
    <ContainerScreens>
      <Row>
        <Col md={10} sm={12}>
          <Separator labelText="Financeiro" sidePadding="0 0" />
        </Col>
        <Col md={2} sm={12}>
          <ButtonItens type="button" onClick={() => financeiroCalcular()}>
            <FaSync color="#28a745" />
            <span>(Re) Calcular</span>
          </ButtonItens>
        </Col>
      </Row>
      <Row style={{ minHeight: '70px', alignItems: 'flex-end' }}>
        <Col md={3} sm={12}>
          <InputSelect
            label="Finalizadora"
            maxLength={50}
            placeholder="Selecione..."
            name="cod_finalizadora"
            disabled={disable}
            register={registerFinanceiro}
            options={finalizadoras}
            isError={!!formStateFinanceiro.errors.cod_finalizadora}
            control={controlFinanceiro}
            changeSelected={(selected: any) => {
              setValueFinanceiro('cod_finalizadora', selected);
            }}
          />
        </Col>
        <CalculoCondicao
          name="condicao"
          register={registerFinanceiro}
          getValues={getValueFinanceiro}
          control={controlFinanceiro}
          disabled={disable}
          setValue={setValueFinanceiro}
          formState={formStateFinanceiro}
          watch={watchFinanceiro}
          fieldToWatch="cod_finalizadora"
          dataEmissao={dtaEmissao}
          dataEntrada={dtaEntrada}
          changeCondicao={(result: any) => {
            handleVencimento(result);
          }}
        />
        <Col sm={12} md={2}>
          <InputDate
            register={registerFinanceiro}
            label="Vencimento"
            name="dta_vencimento"
            control={controlFinanceiro}
            disabled={disable}
            isError={!!formStateFinanceiro.errors.dta_vencimento}
          />
        </Col>
        <Col sm={12} md={2}>
          <InputMoney
            label="Valor"
            placeholder="0,00"
            min={0}
            decimals={2}
            name="val_financeiro"
            disabled={disable}
            register={registerFinanceiro}
            isError={!!formStateFinanceiro.errors.val_financeiro}
          />
        </Col>
      </Row>
      <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Col
          md={2}
          sm={12}
          style={{ display: 'flex', height: '70px', alignItems: 'flex-end' }}
        >
          <ConfirmButton
            disabled={disable}
            style={{ height: '40px', width: '100%' }}
            onClick={() => addItemFinaceiro()}
          >
            {updateFinaceiro ? 'Editar' : 'Adicionar'}
          </ConfirmButton>
        </Col>
        <Col
          md={2}
          sm={12}
          style={{ display: 'flex', height: '70px', alignItems: 'flex-end' }}
        >
          <ClearButton
            disabled={disable}
            style={{ height: '40px', width: '100%' }}
            onClick={() => onClearFinaceiro()}
          >
            Limpar
          </ClearButton>
        </Col>
      </Row>
      <Row>
        <Col sm={12} style={{ marginTop: '15px' }}>
          {datasNaoInformadas === true && (
            <Alert variant="warning">
              Para o cálculo do vencimento é necessário{' '}
              <b
                onClick={() => {
                  // setFocus('dta_emissao');
                  setCurrentTab('nfe');
                }}
                style={{ cursor: 'pointer' }}
              >
                informar
              </b>{' '}
              as datas de emissão e saída da NF.
            </Alert>
          )}
        </Col>
      </Row>
      <br />
      <Row>
        <TableFinanceiro />
      </Row>
      <Row>
        <ContainerTotal>
          <span>Total Financeiro:</span>
          <span>
            {new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(
              itensFinaceiro.reduce(
                (sum, { val_parcela }) => sum + val_parcela,
                0,
              ),
            )}
          </span>
        </ContainerTotal>
      </Row>
    </ContainerScreens>
  );
};
