import React, { useContext, useState } from 'react';
import Modal, {
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalTitle,
} from '@atlaskit/modal-dialog';
import { Row, Col } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';

import { BuscaProduto } from '~/components/NovosInputs/BuscaProduto';
import { InputMoney } from '~/components/NovosInputs/InputMoney2';
import { InputSelect } from '~/components/NovosInputs/InputSelect';

import { useNFOutrasOperacao } from '../../../NFOutrasOperacoesContext';
// eslint-disable-next-line import/named
import { CloseButton, GroupButton } from './styles';
import { toast } from 'react-toastify';
import { LojaContext } from '~/context/loja';
import { CustomButtonNovo } from '~/components/Buttons/CustomButtonNovo';
import { FaEraser } from 'react-icons/fa';

const ModalNovoItem: React.FC = () => {
  const { codLoja } = useContext(LojaContext);
  const [selectProduto, setSelectProduto] = useState(false);
  const {
    loja,
    isOpenModalNovoItem,
    setIsOpenModalNovoItem,
    setTributacaoNovoItem,
    setCfopNovoItem,
    FocusFilter,
    formStateModal,
    setValueModal,
    resetModalItem,
    resetModal,
    buscarProduto,
    tributacoes,
    cfops,
    ncms,
    onSubmitModal,
    updateItemModal,
    isBlockNf,
    registerModal,
    controlModal,
    handleSubmitModal,
  } = useNFOutrasOperacao();

  const handleCloseModal = () => {
    return setIsOpenModalNovoItem(false);
  };

  const handleClickAdd = handleSubmitModal(() => {
    setSelectProduto(true);
    onSubmitModal();
    setTimeout(() => {
      resetModal();
      setValueModal('produto', {
        label: '',
        value: undefined,
      });
    }, 200);
    if (updateItemModal) handleCloseModal();
  });

  return (
    <>
      {isOpenModalNovoItem && (
        <Modal width="x-large" onClose={handleCloseModal}>
          <ModalHeader
            style={{
              paddingBottom: '0.5rem',
            }}
          >
            <ModalTitle>Item da Nota Fiscal</ModalTitle>
            <CloseButton onClick={handleCloseModal}>
              <AiOutlineClose size={24} />
            </CloseButton>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md={10} sm={12}>
                <BuscaProduto
                  label="Buscar Produto"
                  autoFocus
                  getProduct={(e: any) => {
                    if (e[0]?.flg_inativo) {
                      return toast.warning('Produto fora do MIX da loja.');
                    }
                    if (e[0]?.cod_produto && !isBlockNf && !e[0]?.flg_inativo) {
                      buscarProduto(e[0]?.cod_produto);
                      setSelectProduto(false);
                      FocusFilter('qtd_embalagem');
                    }
                  }}
                  id="Buscaproduto"
                  name="produto"
                  register={registerModal}
                  control={controlModal}
                  buscaNasLojas={loja?.cod_loja || codLoja}
                  customOptions={{
                    hideBuscarPor: true,
                    buscarPor: ['Produto'],
                    showSelecionarItensContendo: false,
                  }}
                  isError={selectProduto && !!formStateModal.errors.produto}
                  placeholder="Selecione..."
                />
              </Col>
              <Col
                md={2}
                sm={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                }}
              >
                <CustomButtonNovo
                  onClick={async () => {
                    resetModalItem();
                    FocusFilter('Buscaproduto');
                    setTimeout(() => {
                      resetModal();

                      setValueModal('produto', {
                        label: '',
                        value: undefined,
                      });
                    }, 200);
                  }}
                  variant="clear"
                  disabled={isBlockNf}
                  label="Limpar"
                  icon={FaEraser}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} sm={12} style={{ marginTop: '10px' }}>
                <GroupButton>
                  <label htmlFor="Trib" style={{ width: '50px' }}>
                    Trib:
                  </label>
                  <InputSelect
                    label=""
                    placeholder="Selecione..."
                    id="trib"
                    name="trib"
                    register={registerModal}
                    control={controlModal}
                    isError={selectProduto && !!formStateModal.errors.trib}
                    options={tributacoes}
                    isDisabled={isBlockNf}
                    changeSelected={(selected: any) => {
                      setValueModal('trib', selected);
                      setTributacaoNovoItem(selected);
                    }}
                  />
                </GroupButton>
              </Col>
              <Col md={6} sm={12} style={{ marginTop: '10px' }}>
                <GroupButton>
                  <label htmlFor="NCM" style={{ width: '50px' }}>
                    NCM:
                  </label>
                  <InputSelect
                    label=""
                    placeholder="Selecione..."
                    name="ncm"
                    register={registerModal}
                    isError={selectProduto && !!formStateModal.errors.ncm}
                    control={controlModal}
                    options={ncms}
                    changeSelected={(selected: any) =>
                      setValueModal('ncm', selected)
                    }
                  />
                </GroupButton>
              </Col>
              <Col md={6} sm={12} style={{ marginTop: '10px' }}>
                <GroupButton>
                  <label htmlFor="cfop" style={{ width: '50px' }}>
                    CFOP:
                  </label>
                  <InputSelect
                    label=""
                    placeholder="Selecione..."
                    name="cfop"
                    register={registerModal}
                    isError={selectProduto && !!formStateModal.errors.cfop}
                    control={controlModal}
                    options={cfops}
                    isDisabled={isBlockNf}
                    changeSelected={(selected: any) => {
                      setValueModal('cfop', selected);
                      setCfopNovoItem(selected);
                      FocusFilter('qtd_embalagem');
                    }}
                  />
                </GroupButton>
              </Col>
            </Row>
            <Row>
              <Col md={12} sm={12} style={{ marginTop: '10px' }}>
                <Row>
                  <Col
                    md={12}
                    sm={12}
                    style={{ display: 'grid', gap: '5px', margin: '5px' }}
                  >
                    <Row style={{ background: 'RGB(192, 230, 194)' }}>
                      <Col md={3} sm={12} style={{ padding: '10px' }}>
                        <GroupButton>
                          <label htmlFor="qtd_embalagem">Emb:</label>
                          <InputMoney
                            label=""
                            placeholder=""
                            min={0}
                            decimals={999999}
                            step={1}
                            id="qtd_embalagem"
                            name="qtd_embalagem"
                            register={registerModal}
                            control={controlModal}
                            isError={!!formStateModal.errors.qtd_embalagem}
                            showIcon={false}
                            onKeyPress={(event) => {
                              if (event.key === 'Enter') {
                                onSubmitModal();
                              }
                            }}
                          />
                        </GroupButton>
                      </Col>
                      <Col md={3} sm={12} style={{ padding: '10px' }}>
                        <GroupButton>
                          <label htmlFor="qtd_entrada">Qtd:</label>
                          <InputMoney
                            label=""
                            placeholder=""
                            min={0}
                            decimals={2}
                            step={1}
                            name="qtd_entrada"
                            register={registerModal}
                            control={controlModal}
                            isError={!!formStateModal.errors.qtd_entrada}
                            showIcon={false}
                            onKeyPress={(event) => {
                              if (event.key === 'Enter') {
                                onSubmitModal();
                              }
                            }}
                          />
                        </GroupButton>
                      </Col>
                      <Col md={3} sm={12} style={{ padding: '10px' }}>
                        <GroupButton>
                          <label htmlFor="val_unitario">Valor:</label>
                          <InputMoney
                            label=""
                            id="val_unitario"
                            min={0}
                            decimals={999999}
                            placeholder=""
                            name="val_unitario"
                            register={registerModal}
                            isError={!!formStateModal.errors.val_unitario}
                            control={controlModal}
                            showIcon
                            onKeyPress={(event) => {
                              if (event.key === 'Enter') {
                                onSubmitModal();
                              }
                            }}
                          />
                        </GroupButton>
                      </Col>
                      <Col md={3} sm={12} style={{ padding: '10px' }}>
                        <GroupButton style={{ display: 'none' }}>
                          <label htmlFor="val_meterial">Valor Material:</label>
                          <InputMoney
                            label=""
                            id="val_meterial"
                            min={0}
                            decimals={999999}
                            placeholder=""
                            name="val_material"
                            register={registerModal}
                            isError={!!formStateModal.errors.val_material}
                            control={controlModal}
                            showIcon
                            onKeyPress={(event) => {
                              if (event.key === 'Enter') {
                                onSubmitModal();
                              }
                            }}
                          />
                        </GroupButton>
                      </Col>
                    </Row>

                    <Row style={{ background: '#F3CAC9' }}>
                      <Col md={3} sm={12} style={{ padding: '10px' }}>
                        <GroupButton>
                          <label htmlFor="val_desconto">Desc:</label>
                          <InputMoney
                            label=""
                            id="val_desconto"
                            min={0}
                            decimals={999999}
                            placeholder=""
                            name="val_desconto"
                            register={registerModal}
                            isError={!!formStateModal.errors.val_desconto}
                            control={controlModal}
                            showIcon
                            onKeyPress={(event) => {
                              if (event.key === 'Enter') {
                                onSubmitModal();
                              }
                            }}
                          />
                        </GroupButton>
                      </Col>
                      <Col md={3} sm={12} style={{ padding: '10px' }}>
                        <GroupButton>
                          <label htmlFor="val_despesa_acessoria">Acresc:</label>
                          <InputMoney
                            label=""
                            id="val_despesa_acessoria"
                            min={0}
                            decimals={999999}
                            placeholder=""
                            name="val_despesa_acessoria"
                            register={registerModal}
                            isError={
                              !!formStateModal.errors.val_despesa_acessoria
                            }
                            showIcon
                            control={controlModal}
                            onKeyPress={(event) => {
                              if (event.key === 'Enter') {
                                onSubmitModal();
                              }
                            }}
                          />
                        </GroupButton>
                      </Col>
                      <Col md={3} sm={12} style={{ padding: '10px' }}>
                        <GroupButton>
                          <label htmlFor="val_ipi">IPI:</label>
                          <InputMoney
                            label=""
                            id="val_ipi"
                            min={0}
                            decimals={999999}
                            placeholder=""
                            name="val_ipi"
                            register={registerModal}
                            isError={!!formStateModal.errors.val_ipi}
                            control={controlModal}
                            showIcon
                            onKeyPress={(event) => {
                              if (event.key === 'Enter') {
                                onSubmitModal();
                              }
                            }}
                          />
                        </GroupButton>
                      </Col>
                    </Row>

                    <Row
                      style={{
                        background: '#CADFF8',
                        alignItems: 'center',
                      }}
                    >
                      <Col md={3} sm={12} style={{ padding: '10px' }}>
                        <GroupButton>
                          <label htmlFor="val_bc_st">BC ICMS ST:</label>
                          <InputMoney
                            label=""
                            id="val_bc_st"
                            min={0}
                            decimals={999999}
                            placeholder=""
                            name="val_bc_st"
                            register={registerModal}
                            isError={!!formStateModal.errors.val_bc_st}
                            control={controlModal}
                            showIcon
                            onKeyPress={(event) => {
                              if (event.key === 'Enter') {
                                onSubmitModal();
                              }
                            }}
                          />
                        </GroupButton>
                      </Col>
                      <Col md={3} sm={12} style={{ padding: '10px' }}>
                        <GroupButton>
                          <label htmlFor="val_icms_st">ICMS ST:</label>
                          <InputMoney
                            label=""
                            id="val_icms_st"
                            min={0}
                            decimals={999999}
                            placeholder=""
                            name="val_icms_st"
                            register={registerModal}
                            isError={!!formStateModal.errors.val_icms_st}
                            control={controlModal}
                            showIcon
                            onKeyPress={(event) => {
                              if (event.key === 'Enter') {
                                onSubmitModal();
                              }
                            }}
                          />
                        </GroupButton>
                      </Col>
                      <Col md={3} sm={12} style={{ padding: '10px' }}>
                        <GroupButton>
                          <label htmlFor="val_frete">Frete:</label>
                          <InputMoney
                            label=""
                            id="val_frete"
                            min={0}
                            decimals={999999}
                            placeholder=""
                            name="val_frete"
                            register={registerModal}
                            isError={!!formStateModal.errors.val_frete}
                            control={controlModal}
                            showIcon
                            onKeyPress={(event) => {
                              if (event.key === 'Enter') {
                                onSubmitModal();
                              }
                            }}
                          />
                        </GroupButton>
                      </Col>
                      <Col md={3} sm={12} style={{ padding: '10px' }}>
                        <GroupButton>
                          <label htmlFor="PIS/COFINS">BC PIS/COFINS:</label>
                          <InputMoney
                            label=""
                            id="pis_cofins"
                            min={0}
                            decimals={999999}
                            placeholder=""
                            name="pis_cofins"
                            register={registerModal}
                            isError={!!formStateModal.errors.pis_cofins}
                            control={controlModal}
                            showIcon={false}
                            onKeyPress={(event) => {
                              if (event.key === 'Enter') {
                                onSubmitModal();
                              }
                            }}
                          />
                        </GroupButton>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '10px',
            }}
          >
            <Button
              style={{ background: '#dc3545', color: '#fff', border: 'none' }}
              onClick={() => {
                setIsOpenModalNovoItem(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              style={{
                background: '#28a745',
                color: '#fff',
                border: 'none',
              }}
              onClick={handleClickAdd}
            >
              {updateItemModal ? 'Editar' : 'Adicionar'}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default ModalNovoItem;
