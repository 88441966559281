import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { ContainerScreens, GroupButton } from './styles';
import { InputText } from '~/components/NovosInputs';
import { TableLog } from './TableLog';
import { useNFOutrasOperacao } from '../../NFOutrasOperacoesContext';
import getLogs from '../../services/getLogs';

export const Log: React.FC = () => {
  const { formStateLog, registerLog, cod_xml, setLogs } = useNFOutrasOperacao();
  const [numChaveAcesso, setNumChaveAcesso] = useState<string>('');

  useEffect(() => {
    if (cod_xml !== undefined) {
      (async () => {
        const result = await getLogs({ cod_xml });
        setLogs(result.rows);
        setNumChaveAcesso(result.num_chave_acesso);
      })();
    }
  }, [cod_xml]);
  return (
    <ContainerScreens>
      <Row>
        <Col md={12} sm={12}>
          <GroupButton>
            <label
              htmlFor="cod_xml"
              style={{ width: '80px', marginTop: '20px' }}
            >
              Chave NF-e:
            </label>
            <InputText
              label=""
              value={numChaveAcesso}
              id="cod_xml"
              placeholder=""
              name="cod_xml"
              maxLength={25}
              disabled
              register={registerLog}
              isError={!!formStateLog.errors.cod_xml}
            />
          </GroupButton>
        </Col>
      </Row>
      <br />
      <Row>
        <TableLog />
      </Row>
    </ContainerScreens>
  );
};
